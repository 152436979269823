<template>
  <div class="topUp">
    <div class="top">
      <span>当前店铺 </span>
      <span class="tag">{{ shopName }}</span>
    </div>
    <div class="in">
      <el-button
        @click="toBack"
        size="medium"
        style="color: #333; font-size: 16px"
        icon="el-icon-arrow-left"
        type="text"
        >返回</el-button
      >
    </div>
    <div class="bottom">
      <div class="cell">
        <div class="title">
          充值
          <br />
          <span style="font-size: 14px; color: #666"
            >以下广告储值金面额将于成功付款后三十分钟内汇入广告账户。</span
          >
        </div>
        <div class="content">
          <div style="display: flex">
            <div style="width: 300px; text-align: right">请选择充值金额</div>
            <div class="money-box" v-if="moneyList.length != 0">
              <div
                class="money-item"
                :style="{
                  'border-color': moneyIndex == i ? '#ee4d2d' : '#eee',
                  background: moneyIndex == i ? '#fdedea' : '#fff',
                }"
                @click="handleClick(i)"
                v-for="(item, i) in moneyList"
                :key="i"
              >
                <div style="margin-bottom: 10px">
                  {{ item.name }}
                  <span
                    :style="{
                      color: moneyIndex == i ? '#ee4d2d' : '#000',
                    }"
                  >
                    {{ item.money }}</span
                  >
                </div>

                <span style="font-size: 14px; color: #b0b0b0"
                  >RM{{ item.money }}</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 30px">
            <div style="margin-left: 260px; width: 100%">
              <div style="font-size: 14px; margin-bottom: 10px">
                请输入充值金额
              </div>
              <el-input
                @focus="handleFocus"
                size="small"
                style="width: 200px"
                placeholder="请输入"
                v-model="input1"
              >
                <template slot="prepend">RM</template>
              </el-input>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div style="width: 300px; text-align: right">总价</div>
            <div
              style="
                margin-left: 20px;
                width: 100%;
                font-size: 20px;
                color: #ee4d2d;
              "
              v-if="moneyList.length != 0"
            >
              RM
              <span style="font-weight: 600">{{
                moneyList.length > moneyIndex
                  ? moneyList[moneyIndex].money
                  : input1
              }}</span>
            </div>
          </div>
          <div style="margin-left: 260px; margin-top: 30px">
            <el-button type="primary" @click="toPay">结账</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRechargeAmount } from "@/api/marketing.js";
export default {
  data() {
    return {
      shopName: "",
      moneyList: [],
      moneyIndex: 0,
      input1: "",
    };
  },
  created() {
    if (localStorage.CENTERINFO) {
      this.shopName = JSON.parse(localStorage.CENTERINFO).shop_info.shop_name;
    }
    this.getList();
  },
  methods: {
    getList() {
      getRechargeAmount().then((res) => {
        if (res.code == 1) {
          this.moneyList = res.data;
        }
      });
    },
    handleClick(index) {
      this.moneyIndex = index;
      this.input1 = "";
    },
    toBack() {
      this.$router.go(-1);
    },
    handleFocus() {
      this.moneyIndex = this.moneyList.length + 1;
    },
    toPay() {
      this.$router.push({
        path: "pay",
        query: {
          money:
            this.moneyList.length > this.moneyIndex
              ? this.moneyList[this.moneyIndex].money
              : this.input1,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.topUp {
  width: 1400px;
  padding: 20px;
  margin: 0 auto;
  .top {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.01);
    .tag {
      border: 1px solid #dddde1;
      padding: 6px 20px;
      border-radius: 4px;
      text-align: center;
      margin-left: 20px;
    }
  }
  .in {
    border: 1px solid #dddde1;
    // padding: 10px;
    margin-top: 20px;
    width: 100px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
  }
  .bottom {
    .cell {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.01);
      .title {
        font-size: 22px;
        font-weight: 200;
      }
      .content {
        width: 100%;
        margin-top: 40px;
        .money-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          margin-left: 20px;

          .money-item {
            border: 1px solid #eee;
            border-radius: 4px;
            width: 23%;
            padding: 30px 30px 40px 30px;
            border-radius: 8px;
            margin: 0 10px 10px 0;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>